<template>
  <div :class="$props.class" class="relative text-center">
    <Button
      :label="label || 'Uzmanınızla eşleşin'"
      class="w-full"
      @click="goToFlow"
      :size="size != null ? size : ''"
      :id="id"
    />
  </div>
</template>
<script>
import { useAuthStore } from "~/store/auth";

export default {
  props: {
    size: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup: () => {
    const authStore = useAuthStore();

    return { authStore };
  },

  methods: {
    async goToFlow() {
      const hasToken = this.authStore.token.token;
      if (hasToken) {
        await this.authStore.refreshUser();
      }

      this.$router.push(routes.verticals.path);
    },
  },
};
</script>
